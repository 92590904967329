/** @format */

export default function Custom404() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <span
        style={{
          borderRight: "solid",
          borderWidth: "1px",
          borderColor: "darkgray",
          paddingRight: "1rem",
          marginRight: "1rem",
          fontSize: "1.5rem",
        }}
      >
        404
      </span>
      <span>Seite konnte nicht gefunden werden</span>
    </div>
  );
}
